import React from "react";
import "./App.css";
import { useAuth, UserData } from "./hooks/useAuth";
import { store } from "./store/store";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/theme";
import AppLoader from "./components/loaders/AppLoader";
import { StyledEngineProvider } from "@mui/material/styles";
import './theme/globalVariables.module.css'
const UnauthenticatedApp = React.lazy(() => import(/* webpackChunkName: "unAuthenticatedApp"*/ "./components/auth/UnauthenticatedApp"));
const AuthenticatedApp = React.lazy(
  () => import(/*webpackPrefetch:true, webpackChunkName: "authenticatedApp"*/ "./components/auth/AuthenticatedApp")
);

function App() {
  const { isLoading, isAuthenticated, user, handleLogout } = useAuth();

  if (isLoading) {
    return <AppLoader />;
  }

  if (user) {
    store.setState<UserData>("user", user, { persist: true });
    store.setState<Function>("logout", handleLogout);
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}</ThemeProvider>{" "}
    </StyledEngineProvider>
  );
}

export default App;
